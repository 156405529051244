<template>
  <div :class="cloudPage" class="cloud-browse">
    <Banner>
      <div class="banner-content">
        <MeepIconCloud
          class="banner-content__icon banner-content__icon-stroke"
        />
        <span class="banner-content__text">{{ $t("menu.cloud") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :hasActions="!!actions.length"
      :hasSearch="false"
      :tabs="tabs"
      @change-tab="changeTab"
    >
      <template #right>
        <div class="cloud-browse__buttons">
          <md-button
            v-for="(button, index) in actions"
            :key="index"
            :md-ripple="false"
            class="md-raised header-action header-action-icon md-primary"
            @click="smallButtonClick(button.actionName)"
          >
            <component
              :is="button.icon"
              :class="{
                'app-icon-stroke-inverted': button.stroke,
                'app-icon-inverted': button.inverted && !button.stroke,
                'app-icon': !button.stroke,
              }"
            />

            <md-tooltip md-direction="bottom">
              {{ button.label }}
            </md-tooltip>
          </md-button>
        </div>
      </template>
    </PageHeader>
    <div class="cloud-utils">
      <div class="cloud-path">
        <template v-if="showAll">
          <md-button
            :class="{ 'cloud-blue': isRootFolder }"
            class="md-dense md-primary--inverted"
            to="/dashboard/cloud/"
          >
            Cloud
          </md-button>

          <div
            v-for="(folder, index) in pathToCurrentFile"
            :key="index"
            class="cloud-path__item"
          >
            <md-icon>navigate_next</md-icon>

            <md-button
              :class="{
                'md-primary--inverted': index < pathToCurrentFile.length - 1,
              }"
              :to="'/dashboard/cloud/' + folder.id"
              class="md-dense"
            >
              {{ getFolderName(folder.file_name) }}

              <MeepIconLockPath v-if="folder.is_locked" class="app-icon" />
            </md-button>
          </div>
        </template>
      </div>

      <div class="page-search">
        <input
          v-model="query"
          class="page-search__input"
          type="text"
          @input="onSearch"
        />

        <MeepIconSearchi class="page-search__icon" />
      </div>
    </div>
    <div class="cloud-browse-content page-layout">
      <!-- Le chemin (le fil d'ariane) -->

      <!-- L'affichage en liste -->
      <template v-if="!isLoading">
        <sortable-list
          v-if="
            showAll &&
            ((currentFile.is_directory && currentFile.parent_directory) ||
              isRootFolder)
          "
          ref="fullList"
          :bypass-selectable="!currentFile.can_be_written"
          :items="getFiles"
          :not-found-title="notFoundTitle"
          :not-found-label="notFoundLabel"
          :not-found-text="
            currentFile.is_locked ? '' : $t('cloud.noitem.button-text')
          "
          :notFoundClick="smallButtonClick"
          :parameters="listParameters"
          class="cloud-list"
          default-sort="name"
          default-sort-type="desc"
          link="/dashboard/cloud/"
          notFoundClickText="upload"
          selectable="multiple"
          @selected="onSelect"
          @menu-click="onMenuClicked"
          @item-click="onUnlockSingleFolder"
        />

        <div
          v-else-if="
            currentFile.is_directory &&
            !currentFile.parent_directory &&
            !isRootFolder &&
            showAll
          "
          class="cloud-browse-company-folders"
        >
          <SettingCard
            v-if="isAdmin"
            :buttonText="$t('cloud.open')"
            :content="$$filters.formatTimestamp(files[0].created_at)"
            :isMedium="true"
            :link="'/dashboard/cloud/' + files[0].id"
            :title="$t('cloud.cabinet')"
          >
            <MeepIconSettingCompanies class="app-icon" />
          </SettingCard>
          <SettingCard
            :buttonText="$t('cloud.open')"
            :content="
              isAdmin
                ? $$filters.formatTimestamp(files[1].created_at)
                : $$filters.formatTimestamp(files[0].created_at)
            "
            :isMedium="true"
            :link="cloudDepoLink"
            :title="$t('cloud.depo')"
          >
            <MeepIconCloudDepot class="app-icon" />
          </SettingCard>

          <SettingCard
            v-if="!isEmployee"
            :buttonText="$t('cloud.open')"
            :content="
              isAdmin
                ? $$filters.formatTimestamp(files[2].created_at)
                : $$filters.formatTimestamp(files[1].created_at)
            "
            :isMedium="true"
            :link="cloudDocumentLink"
            :title="$t('cloud.document')"
          >
            <MeepIconCloudDocument class="app-icon" />
          </SettingCard>
        </div>

        <sortable-list
          v-if="!showAll"
          :items="getFiles"
          :parameters="trashListParameters"
          class="cloud-list"
          default-sort="is_directory"
          selectable="multiple"
          @selected="onSelect"
          @menu-click="onMenuClicked"
          @item-click="onUnlockSingleFolder"
        />

        <!-- La visionneuse de fichiers -->
        <template v-if="!(currentFile.is_directory || isRootFolder)">
          <template v-if="!error">
            <template v-if="canVisualized">
              <div v-if="isExcel" id="xlsx-table" class="xlsx-table" />
              <!-- Visionner un pdf -->
              <div v-if="isDocument" id="pdf-container"></div>

              <!-- Visionner une image -->
              <div v-else v-viewer class="image-container">
                <img :src="dataUrl" alt class="image-preview" />
              </div>
            </template>

            <md-card v-else>
              <!-- Le fichier ne peut être visionné -->
              <md-empty-state
                md-description="Vous pouvez néanmoins le télécharger"
                md-icon="insert_drive_file"
                md-label="Ce fichier ne peut être visionné"
              >
                <md-button
                  class="md-primary md-raised"
                  @click.native="onSaveFile"
                >
                  Télécharger {{ currentFile.file_name }}
                  <md-icon>file_download</md-icon>
                </md-button>
              </md-empty-state>
            </md-card>
          </template>

          <template v-else>
            <md-card>
              <md-empty-state
                md-description="Une erreur est survenue lors de la connexion au cloud, Merci de réessayer dans quelques minutes"
                md-icon="error"
                md-label="Zut!"
              />
            </md-card>
          </template>
        </template>
      </template>

      <LoadingCard v-else />

      <!-- Pop up de renommage de fichier-->
      <text-prompt-modal
        v-if="isTextModalOpen"
        :default-text="textPromptParams.defaultText"
        :icon="textPromptParams.icon"
        :label="textPromptParams.label"
        :title="textPromptParams.title"
        :validation="textPromptParams.validation"
        @close="isTextModalOpen = false"
        @validate="textPromptAction"
      >
        <template
          v-if="this.textModalContext === ACTIONS.NEW_YEAR_FOLDER"
          #icon
        >
          <MeepIconCreateYearFolder class="app-icon-big" />
        </template>
        <template
          v-else-if="this.textModalContext === ACTIONS.NEW_FOLDER"
          #icon
        >
          <MeepIconCreateFolder class="app-icon app-icon-big" />
        </template>
        <template v-else-if="this.textModalContext === ACTIONS.RENAME" #icon>
          <MeepIconEditBlue class="app-icon app-icon-big" />
        </template>
      </text-prompt-modal>

      <!-- Pop-up de confirmation de suppression -->
      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="selectedFiles"
        :text="confirmModalText"
        @close="isConfirmModalOpen = false"
        @confirm="confirmAction"
      >
        <template v-if="this.confirmModalContext === ACTIONS.MANAGE" #icon>
          <MeepIconCheckBig class="app-icon app-icon-stroke app-icon-default" />
        </template>
        <template v-else-if="this.confirmModalContext === ACTIONS.DELETE" #icon>
          <MeepIconDelete class="app-icon app-icon-default" />
        </template>
      </confirm-action-modal>

      <!-- Upload invisible -->
      <input
        id="file-upload"
        ref="fileUpload"
        multiple
        name="inputFile"
        onclick="this.value = null;"
        type="file"
        @change="onFilesAdded"
      />

      <!-- Le dock -->
      <md-list
        v-show="uploadQueue.length || cutFiles.length"
        class="cloud-dock"
      >
        <!-- Le presse papier -->
        <md-list-item v-show="cutFiles.length">
          <span class="md-list-item-text">
            Presse papier : {{ cutFiles.length }} élément(s)
          </span>

          <!-- Vider le presse papier -->
          <md-button
            class="md-icon-button md-list-action"
            style="margin-right: 24px"
            @click.native="cutFiles = []"
          >
            <md-icon>clear</md-icon>

            <md-tooltip md-delay="400" md-direction="top">
              Vider le presse papier
            </md-tooltip>
          </md-button>

          <!-- La liste des éléments coupés-->
          <md-list slot="md-expand">
            <md-list-item v-for="(file, index) in cutFiles" :key="index">
              <md-icon
                :class="$$filters.formatExtension(file)"
                class="cloud-file-icon"
                >{{ $$filters.formatExtension(file) }}
              </md-icon>

              <!-- Le nom du fichier -->
              <div class="md-list-item-text">
                <span>{{ file.file_name }}</span>
              </div>

              <!-- Bouton pour enlever l'élément de la liste -->
              <md-button
                class="md-icon-button md-list-action"
                @click.native="cutFiles.splice(index, 1)"
              >
                <md-icon>clear</md-icon>

                <md-tooltip md-delay="400" md-direction="top">
                  Retirer de la liste
                </md-tooltip>
              </md-button>
            </md-list-item>
          </md-list>
        </md-list-item>

        <!-- La liste des fichiers à uploader -->
        <md-list-item
          v-show="uploadQueue.length"
          :md-expanded="true"
          class="upload-status"
        >
          <span class="md-list-item-text upload-title">
            Fils d'attente : {{ uploadQueue.length }} fichier(s)
          </span>

          <md-button
            class="md-button md-icon-button md-list-action md-theme-default"
            @click="uploadQueue = []"
          >
            <MeepIconTopBarBoxClose
              :style="{ width: '30px', marginRight: '2px' }"
              class="app-icon-stroke"
            />
            <md-tooltip md-delay="400" md-direction="top">
              Retirer de la liste
            </md-tooltip>
          </md-button>
        </md-list-item>

        <md-list-item v-for="(file, index) in uploadQueue" :key="index">
          <div class="icon-circle">
            <component
              :is="$$filters.getIconItem($$filters.formatExtension(file))"
              v-if="$$filters.getIconItem($$filters.formatExtension(file))"
              class="app-icon-inverted toolbar-icon"
            />
          </div>

          <!-- Le nom du fichier -->
          <div
            :class="file.isFailed ? 'failure' : 'success'"
            class="md-list-item-text"
          >
            <router-link
              v-if="file.isFinished"
              :to="'/dashboard/cloud/' + file.id"
            >
              {{ file.file_name }}
            </router-link>

            <span v-else>
              {{ file.file_name }}
            </span>
          </div>

          <!-- Spinner indiquant le chargement du fichier -->
          <md-progress-spinner
            v-show="!file.isFinished && !file.isFailed"
            :md-diameter="24"
            :md-stroke="2"
            md-mode="indeterminate"
          />

          <!-- Bouton pour enlever l'élément de la liste -->
          <md-button
            v-if="file.isFinished || file.isFailed"
            class="md-icon-button md-list-action"
            @click.native="uploadQueue.splice(index, 1)"
          >
            <MeepIconActionDelete
              :style="{ width: '30px', marginRight: '2px', textAlign: 'right' }"
              class="app-icon"
            />
            <md-tooltip md-delay="400" md-direction="top">
              Retirer de la liste
            </md-tooltip>
          </md-button>
        </md-list-item>
      </md-list>
    </div>
  </div>
</template>

<script>
/* Modèles */
import filesModel from "../../model/files";
import companiesModel from "../../model/companies";
/* Composants */
import textPromptModal from "../../components/modal/text-prompt";
import confirmActionModal from "../../components/modal/confirm-action";
import Banner from "@/components/Banner";
import SettingCard from "@/components/SettingCard";
import MeepIconSettingCompanies from "@/components/icons/MeepIconSettingCompanies.vue";
import MeepIconCloud from "@/components/icons/MeepIconCloud.vue";
import MeepIconEdit from "@/components/icons/MeepIconEdit.vue";
import MeepIconEditBlue from "@/components/icons/MeepIconEditBlue.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import MeepIconActionRestore from "@/components/icons/MeepIconActionRestore.vue";
import MeepIconActionCut from "@/components/icons/MeepIconActionCut.vue";
import MeepIconActionPaste from "@/components/icons/MeepIconActionPaste.vue";
import MeepIconActionImport from "@/components/icons/MeepIconActionImport.vue";
import MeepIconCut from "@/components/icons/MeepIconCut.vue";
import MeepIconCreateFolder from "@/components/icons/MeepIconCreateFolder.vue";
import MeepIconCreateYearFolder from "@/components/icons/MeepIconCreateYearFolder.vue";
import MeepIconCloudDepot from "@/components/icons/MeepIconCloudDepot.vue";
import MeepIconCloudDocument from "@/components/icons/MeepIconCloudDocument.vue";
import MeepIconManage from "@/components/icons/MeepIconManage.vue";
import MeepIconDownload from "@/components/icons/MeepIconDownload.vue";
import MeepIconTopBarBoxClose from "@/components/icons/MeepIconTopBarBoxClose.vue";
import MeepIconCheckBig from "@/components/icons/MeepIconCheckBig.vue";
import MeepIconDelete from "@/components/icons/MeepIconDelete.vue";
import MeepIconSearchi from "@/components/icons/MeepIconSearchi.vue";
import MeepIconLock from "@/components/icons/MeepIconLock.vue";
import MeepIconLockPath from "@/components/icons/MeepIconLockPath.vue";
import MeepIconActionLock from "@/components/icons/MeepIconActionLock.vue";
import MeepIconActionUnlock from "@/components/icons/MeepIconActionUnlock.vue";

/* Fonctions utiles */
import {
  blobToDataURL,
  createBlobFromBinary,
  getFileExtension,
  throttle,
} from "@/services/util";
/* Pour la sauvegarde de fichiers */
import FileSaver from "file-saver";
/* Visionneuse */
import PDFObject from "pdfobject";
import XLSX from "xlsx";
import canvasDatagrid from "canvas-datagrid";
import { mapGetters } from "vuex";
import PageHeader from "@/components/PageHeader";
import Fuse from "fuse.js";
import LoadingCard from "@/components/LoadingCard";
import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import Vue from "vue";

Vue.use(Viewer);
const ACTIONS = {
  PASTE: "paste",
  RENAME: "rename",
  DELETE: "delete",
  MANAGE: "manage",
  CUT: "cut",
  UPLOAD: "upload",
  NEW_FOLDER: "newFolder",
  NEW_YEAR_FOLDER: "newYearFolder",
  DELETE_PERMANENT: "deletePermanent",
  RESTORE: "restore",
  DOWNLOAD: "download",
  LOCK: "lock",
  UNLOCK: "unlock",
};

const isDirectory = (item) =>
  item.is_directory === 1 || item.is_directory === true;

export default {
  name: "Cloud",

  components: {
    LoadingCard,
    PageHeader,
    "text-prompt-modal": textPromptModal,
    "confirm-action-modal": confirmActionModal,
    Banner,
    SettingCard,
    MeepIconCloud,
    MeepIconSettingCompanies,
    MeepIconEdit,
    MeepIconActionDelete,
    MeepIconActionImport,
    MeepIconCut,
    MeepIconCreateFolder,
    MeepIconCreateYearFolder,
    MeepIconManage,
    MeepIconDownload,
    MeepIconCloudDepot,
    MeepIconCloudDocument,
    MeepIconActionCut,
    MeepIconActionPaste,
    MeepIconActionRestore,
    MeepIconTopBarBoxClose,
    MeepIconCheckBig,
    MeepIconEditBlue,
    MeepIconDelete,
    MeepIconSearchi,
    MeepIconLock,
    MeepIconLockPath,
    MeepIconActionLock,
    MeepIconActionUnlock,
  },

  data() {
    return {
      query: "",
      error: false,
      isLoading: true /* Si il y a un chargement */,
      isRootFolder: false /* Si on se trouve à la racine */,

      pathToCurrentFile: [] /* Chemin vers le dossier ou fichier courant */,
      currentFile: {} /* Dossier ou fichier courrant */,

      trashFiles: [],
      files: [] /* Fichiers contenus dans le dossier courant */,
      selectedFiles: [] /* La liste des fichiers sélectionnés */,
      uploadQueue: [] /* Fichiers dans la file d'attente */,
      searchResults: [] /* Résultats de la recherche */,
      cutFiles: [] /* La liste des fichiers coupés */,
      dataUrl: null,
      isConfirmModalOpen: false,
      isTextModalOpen: false,
      textModalContext: "",
      confirmModalContext: "",

      currentTab: "tab-all",

      trashListParameters: [
        {
          name: "",
          key: "is_directory",
          isIcon: true,
          iconFormat: this.$$filters.formatExtension,
          class: "cloud-browse__icon",
          /* Cette classe css se trouve dans app/styles/general.scss */
          iconClass: function (file) {
            return "cloud-file-icon " + this.iconFormat(file);
          },
          width: "5%",
        },
        {
          name: "Nom",
          key: "file_name",
          width: "30%",
        },
        {
          name: this.$t("form.created-at"),
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
        {
          name: "Taille du fichier",
          key: "size",
          format: (_, file) => {
            if (isDirectory(file)) {
              return "";
            } else {
              return this.$$filters.formatFileSize(file.size);
            }
          },
        },
        {
          name: "",
          key: "",
          isMenu: true,
          showMenu: (item) => !isDirectory(item),
          menuItems: [
            {
              label: () => this.$t("cloud-menu.delete-forever"),
              actionName: ACTIONS.DELETE,
            },
            {
              label: () => this.$t("cloud-menu.restore"),
              actionName: ACTIONS.RESTORE,
            },
          ],
        },
      ],

      ACTIONS,
    };
  },

  computed: {
    ...mapGetters([
      "isAdmin",
      "isCollab",
      "isExternal",
      "isClient",
      "isEmployee",
      "isJEP",
      "isJEPA",
      "isLCM",
      "isGC",
      "canAccessLockedFolder",
    ]),

    cloudPage() {
      return {
        "cloud-page--jepa": this.isJEPA,
        "cloud-page--lcm": this.isLCM,
        "cloud-page--gc": this.isGC,
      };
    },

    confirmModalText() {
      switch (this.confirmModalContext) {
        case ACTIONS.DELETE:
          return {
            header: "Suppression",
            body(object) {
              return (
                "Vous êtes sur le point de supprimer <b>" +
                object.length +
                "</b> élément" +
                (object.length > 1 ? "s." : ".")
              );
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };

        case ACTIONS.DELETE_PERMANENT:
          return {
            header: "Suppression",
            body(object) {
              return (
                "Vous êtes sur le point de supprimer définitivement <b>" +
                object.length +
                " élément" +
                (object.length > 1 ? "s.</b>" : ".</b>")
              );
            },
            question: "Êtes vous sûr de vouloir le faire ?",
          };

        case ACTIONS.MANAGE:
          return {
            header: "Traitement",
            body: (object) => {
              if (object.length === 1) {
                return this.$t("cloud-menu.actions.manage-one-text");
              } else
                return this.$t("cloud-menu.actions.manage-multi-text", {
                  number: object.length,
                });
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };

        case ACTIONS.LOCK:
          return {
            header: this.$t("cloud-menu.actions.lock-modal-label"),
            body: (object) => {
              return object.length > 1
                ? this.$t("cloud-menu.actions.lock-modal-text-multi", {
                    number: object.length,
                  })
                : this.$t("cloud-menu.actions.lock-modal-text");
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };

        case ACTIONS.UNLOCK:
          return {
            header: this.$t("cloud-menu.actions.unlock-modal-label"),
            body: (object) => {
              return object.length > 1
                ? this.$t("cloud-menu.actions.unlock-modal-text-multi", {
                    number: object.length,
                  })
                : this.$t("cloud-menu.actions.unlock-modal-text");
            },
            question: "Êtes vous sûr de vouloir continuer ?",
          };
        default:
          return "";
      }
    },

    textPromptParams() {
      switch (this.textModalContext) {
        case ACTIONS.NEW_FOLDER:
          return {
            title: this.$t("cloud-menu.actions.new-folder-title"),
            label: this.$t("cloud-menu.actions.new-folder-label"),
            icon: "folder",
            defaultText: "",
          };
        case ACTIONS.NEW_YEAR_FOLDER:
          return {
            title: this.$t("cloud-menu.actions.new-year-folder-title"),
            label: this.$t("cloud-menu.actions.new-year-folder-label"),
            icon: "folder",
            validation: "required|numeric",
            defaultText: "",
          };
        case ACTIONS.RENAME:
          return {
            title: this.$t("cloud-menu.actions.rename-title"),
            label: this.$t("cloud-menu.actions.rename-label"),
            icon: "edit",
            defaultText: this.selectedFiles[0].file_name,
          };
        default:
          return "";
      }
    },

    tabs() {
      if (this.isAdmin) {
        return [
          {
            id: "tab-all",
            label: "Tous",
          },
          {
            id: "tab-trashed",
            label: "Corbeille",
          },
        ];
      }

      return [];
    },

    hasSearch() {
      return this.currentFile.is_directory || !this.showAll;
    },

    isDocument() {
      return this.currentFile.fileExtension === "pdf";
    },

    isExcel() {
      return this.currentFile.fileExtension === "xlsx";
    },

    showAll() {
      return this.currentTab === "tab-all" || !this.currentTab;
    },

    canVisualized() {
      return ["pdf", "jpg", "png", "xlsx", "pdf", "jpeg"].includes(
        this.currentFile.fileExtension
      );
    },

    isLockedFolder() {
      if (this.canAccessLockedFolder) {
        return false;
      }
      return this.currentFile.is_locked;
    },

    /* à completer */
    canWrite() {
      /* On ne doit pas pouvoir écrire dans : */
      return (
        !(
          this.isRootFolder /* la racine */ ||
          !this.currentFile.parent_directory /* un dossier d'(E) */ ||
          !this.currentFile.is_directory /* un fichier */ ||
          !this.currentFile.can_be_written ||
          this.currentFile.is_locked ||
          this.isClientInDocument
        ) && !this.isLockedFolder
      );
    },

    canCut() {
      return (
        this.canWrite &&
        this.selectedFiles &&
        this.selectedFiles.length &&
        this.query === ""
      );
    },

    canPaste() {
      return (
        this.canWrite &&
        this.cutFiles &&
        this.cutFiles.length &&
        this.currentFile.id !== this.cutFiles[0].parent_directory &&
        this.query === ""
      );
    },

    canDelete() {
      return this.canWrite && this.selectedFiles && this.selectedFiles.length;
    },

    canRename() {
      return (
        this.canWrite && this.selectedFiles && this.selectedFiles.length === 1
      );
    },

    notIncludeFile() {
      let valid = true;
      this.selectedFiles.forEach((item) => {
        if (!item.is_directory) {
          valid = false;
          return;
        }
      });
      return valid;
    },

    canLockFolder() {
      return (
        this.canAccessLockedFolder &&
        this.selectedFiles.length &&
        this.notIncludeFile
      );
    },

    canManage() {
      return (
        this.canWrite &&
        this.selectedFiles &&
        this.selectedFiles.length &&
        (this.isAdmin || this.isCollab) // Only for admin and collaborator
      );
    },

    canDownload() {
      return !this.isRootFolder;
    },

    actions() {
      const results = [];

      if (this.showAll) {
        if (this.canPaste) {
          results.push({
            actionName: ACTIONS.PASTE,
            isSVG: false,
            icon: MeepIconActionPaste,
            inverted: true,
            label: this.$t("cloud-menu.paste"),
          });
        }

        if (this.canRename) {
          results.push({
            actionName: ACTIONS.RENAME,
            isSVG: false,
            icon: MeepIconEdit,
            label: this.$t("cloud-menu.rename"),
          });
        }

        if (this.canDelete) {
          results.push({
            actionName: ACTIONS.DELETE,
            isSVG: false,
            icon: MeepIconActionDelete,
            label: this.$t("cloud-menu.delete-forever"),
          });
        }

        if (this.canCut) {
          results.push({
            actionName: ACTIONS.CUT,
            isSVG: false,
            icon: MeepIconActionCut,
            label: this.$t("cloud-menu.cut"),
          });
        }

        if (this.canWrite) {
          results.push(
            {
              actionName: ACTIONS.UPLOAD,
              isSVG: false,
              icon: MeepIconActionImport,
              label: this.$t("cloud-menu.import-file"),
            },
            {
              actionName: ACTIONS.NEW_YEAR_FOLDER,
              isSVG: false,
              icon: MeepIconCreateYearFolder,
              label: this.$t("cloud-menu.new-year-folder"),
            },
            {
              actionName: ACTIONS.NEW_FOLDER,
              isSVG: false,
              icon: MeepIconCreateFolder,
              label: this.$t("cloud-menu.new-folder"),
            }
          );
        }

        if (this.canLockFolder) {
          results.push({
            actionName: ACTIONS.LOCK,
            isSVG: false,
            icon: MeepIconActionLock,
            label: this.$t("cloud-menu.actions.lock-label"),
          });
        }

        if (this.canLockFolder) {
          results.push({
            actionName: ACTIONS.UNLOCK,
            isSVG: false,
            icon: MeepIconActionUnlock,
            label: this.$t("cloud-menu.actions.unlock-label"),
          });
        }

        if (this.canManage) {
          results.push({
            actionName: ACTIONS.MANAGE,
            isSVG: false,
            icon: MeepIconManage,
            stroke: true,
            label: this.$t("cloud-menu.manage"),
          });
        }

        if (this.canDownload) {
          results.push({
            actionName: ACTIONS.DOWNLOAD,
            isSVG: false,
            icon: MeepIconDownload,
            label: this.downloadLabel,
          });
        }
      } else {
        if (this.selectedFiles.length > 0) {
          results.push(
            {
              actionName: ACTIONS.DELETE_PERMANENT,
              isSVG: false,
              icon: MeepIconActionDelete,
              label: "Supprimer définitivement",
            },
            {
              actionName: ACTIONS.RESTORE,
              isSVG: false,
              icon: MeepIconActionRestore,
              label: "Restaurer",
            }
          );
        }
      }

      return results;
    },

    isClientInDocument() {
      const isParentMydocuments = this.pathToCurrentFile.find(
        (file) =>
          file.file_name === "Dépôt externe" ||
          file.file_name === "Mes documents"
      );

      return this.isClient && isParentMydocuments;
    },

    currentFileList() {
      return this.showAll ? this.files : this.trashFiles;
    },

    /* Les données qui seront passées dans la liste */
    getFiles() {
      if (this.query === "") {
        return this.currentFileList;
      }

      if (this.showAll) {
        // Non trash search results
        return this.searchResults;
      }

      const fuse = new Fuse(this.trashFiles, {
        shouldSort: true,
        threshold: 0.4,
        distance: 100,
        keys: ["file_name", "company_name"],
      });

      return fuse.search(this.query).map((item) => item.item);
    },

    /* Colonnes de la vue liste */
    listParameters() {
      let param = [
        {
          name: "",
          key: "is_directory",
          isIcon: true,
          iconFormat: this.$$filters.formatExtension,
          /* Cette classe css se trouve dans app/styles/general.scss */
          iconClass: function (file) {
            return "cloud-file-icon " + this.iconFormat(file);
          },
          class: "cloud-browse__icon",
          width: "5%",
        },
        {
          name: this.firstColumnName,
          key: "file_name",
          class: this.isJEPA
            ? "md-primary--jepa--inverted cloud-browse__file-name"
            : "cloud-browse__file-name",
          width: window.innerHeight > 1280 ? "30%" : "20%",
        },
        {
          name: this.$t("cloud-menu.tables.created_at-name"),
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
      ];

      if (this.$route.params.id) {
        param.push(
          {
            name: this.$t("cloud-menu.tables.size-name"),
            key: "size",
            format: (size, file) => {
              if (file.is_directory) {
                return "";
              } else {
                return this.$$filters.formatFileSize(size);
              }
            },
          },
          {
            name: "",
            key: "is_locked",
            isIcon: true,
            iconFormat: this.$$filters.formatLock,
            /* Cette classe css se trouve dans app/styles/general.scss */
            iconClass: function (file) {
              return "cloud-file-icon " + this.iconFormat(file);
            },
            clickable: true,
            class: "cloud-browse__lock-icon",
            width: "5%",
          },
          {
            name: "",
            key: "",
            isMenu: true,
            showMenu: (item) => !isDirectory(item),
            menuItems: [
              {
                label: () => this.$t("cloud-menu.new-year-folder"),
                actionName: ACTIONS.NEW_YEAR_FOLDER,
              },
              {
                label: () => this.$t("cloud-menu.cut"),
                actionName: ACTIONS.CUT,
              },
              {
                label: () => this.$t("cloud-menu.new-folder"),
                actionName: ACTIONS.NEW_FOLDER,
              },
              {
                label: () => this.$t("cloud-menu.import-file"),
                actionName: ACTIONS.UPLOAD,
              },
              {
                label: () => this.$t("cloud-menu.manage"),
                actionName: ACTIONS.MANAGE,
              },
              {
                label: (item) =>
                  isDirectory(item) === true
                    ? this.$t("cloud-menu.delete-folder")
                    : this.$t("cloud-menu.delete-file"),
                actionName: ACTIONS.DELETE,
              },

              {
                label: (item) =>
                  isDirectory(item) === true
                    ? this.$t("cloud-menu.download-folder")
                    : this.$t("cloud-menu.download-file"),
                actionName: ACTIONS.DOWNLOAD,
              },
            ],
          }
        );
      }
      if ((this.isAdmin || this.isCollab) && this.$route.params.id) {
        param.splice(param.length - 2, 0, {
          name: this.$t("cloud-menu.tables.is_managed-name"),
          key: "is_managed",
          status: true,
          width: "25%",
        });
      }

      return param;
    },

    firstColumnName() {
      if (this.$route.params.id) {
        return this.$t("cloud-menu.tables.file_name-name");
      } else if (this.isJEPA) {
        return this.$t("cloud-menu.tables.file_name-client");
      }
      return this.$t("cloud-menu.tables.file_name-company");
    },

    downloadLabel() {
      return this.selectedFiles.length <= 1
        ? this.$t("cloud-menu.download-file")
        : this.$t("cloud-menu.download-folder");
    },

    cloudDepoLink() {
      const depotFolder = this.files.find(
        (file) =>
          file.is_in_client_repository && !file.is_in_external_repository
      );
      let id = this.$route.params.id;

      if (depotFolder) {
        id = depotFolder.id;
      } else {
        console.log("Cannot found depot folder");
      }

      return `/dashboard/cloud/${id}`;
    },

    cloudDocumentLink() {
      const documentFolder = this.files.find(
        (file) => file.is_in_client_repository && file.is_in_external_repository
      );
      let id = this.$route.params.id;
      if (documentFolder) {
        id = documentFolder.id;
      } else {
        console.log("Cannot found document folder");
      }
      return `/dashboard/cloud/${id}`;
    },

    notFoundTitle() {
      return this.currentFile.is_locked
        ? "Ce dossier n'est plus accessible."
        : "";
    },

    notFoundLabel() {
      return this.currentFile.is_locked
        ? "Ce dossier est désormais verrouillé par l'administrateur."
        : this.query === ""
        ? this.$t("cloud.noitem.text")
        : "Aucun élément trouvé";
    },
  },

  watch: {
    /* Changement de répertoire */
    $route(to) {
      this.query = "";
      this.selectedFiles = []; /* Vider la liste des fichiers selectionnés */
      this.getFile(to.params.id); /* */
    },
  },

  async created() {
    if (!this.isAdmin) {
      this.getFile(this.$route.params.id);
    }
  },

  methods: {
    convertBlobToDataURL(blob) {
      return new Promise((resolve) => {
        blobToDataURL(blob, (data) => {
          resolve(data);
        });
      });
    },

    onMenuClicked({ actionName, item }) {
      this.selectedFiles = [item];
      this.smallButtonClick(actionName);
    },

    async changeTab(tabId) {
      this.query = "";
      this.selectedFiles = [];
      this.currentTab = tabId;

      if (this.currentTab === "tab-all") {
        this.getFile(this.$route.params.id);
      } else if (this.currentTab === "tab-trashed") {
        this.isLoading = true;
        this.trashFiles = await filesModel.getTrashed();
        this.isLoading = false;
      }
    },

    /* Récupérer le dossier racine */
    async getRootFolder() {
      this.files = await filesModel.getRoot();
      this.pathToCurrentFile = [];
      this.currentFile = {
        id: null,
        is_directory: true,
      };

      /* Terminer le chargement */
      this.isLoading = false;
    },

    /* Récupérer le fichier ou le dossier courant et son contenu */
    async getFile(id) {
      this.isLoading = true;
      if (!id) {
        this.isRootFolder = true;
        this.getRootFolder();

        return;
      }

      this.isRootFolder = false;

      try {
        const data = await filesModel.getFile(id);

        this.pathToCurrentFile = data.path.reverse(); /* Le chemin */
        this.currentFile = data.file;

        if (!isDirectory(this.currentFile)) {
          this.dataUrl = null;

          /* Recuperer l'extension de fichier */
          this.currentFile.fileExtension = getFileExtension(
            this.currentFile.file_name
          );

          if (this.canVisualized) {
            const file = await this.downloadFile(this.currentFile, false);

            this.error = false;
            this.isLoading = false;

            file.blob = createBlobFromBinary(file.binary, file.mimeType);
            this.dataUrl = await this.convertBlobToDataURL(file.blob);

            if (this.isExcel) {
              /* read workbook */
              const wb = XLSX.read(file.binary, {
                type: "binary",
              });

              /* grab first sheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];

              /* generate HTML */
              const json = XLSX.utils.sheet_to_json(ws, {
                raw: true,
                defval: null,
              });

              /* update table */
              const grid = canvasDatagrid({
                parentNode: document.getElementById("xlsx-table"),
                data: json,
              });

              grid.style.width = "100%";
              grid.style.height = "100%";
            }

            if (this.isDocument) {
              PDFObject.embed(URL.createObjectURL(file.blob), "#pdf-container");
            }
          } else {
            this.isLoading = false;
          }
        } else {
          this.files = data.files;
        }

        this.error = false;
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: err.msg,
        });

        // Get file is success ?
        if (this.currentFile) {
          this.error = true;

          // download file is failed
          this.currentFile = {
            id: id,
            is_directory: false,
            can_be_written: false,
          };
        } else {
          this.currentFile = {
            id: id,
            is_directory: true,
            can_be_written: false,
          };
        }
      } finally {
        this.isLoading = false;
      }
    },

    /* Télécharger le fichier ou dossier courant */
    async downloadFile(file, saveFile = true) {
      if (isDirectory(file)) {
        const blob = await filesModel.downloadDirectory(file.id);
        const company = await companiesModel.get(file.company_id);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = url;
        const fileName = this.$$filters.fileNameFilter(company.name);
        link.setAttribute("download", `${fileName}.zip`); //or any other extension

        document.body.appendChild(link);

        link.click();

        this.error = false;
        this.isLoading = false;
      } else {
        if (saveFile) {
          const fileBinary = await filesModel.download(file.id);
          fileBinary.blob = createBlobFromBinary(
            fileBinary.binary,
            fileBinary.mimeType
          );

          FileSaver.saveAs(fileBinary.blob, fileBinary.name);
        } else {
          return await filesModel.download(file.id);
        }
      }
    },

    /* Retourner en arrière (avec la flèche) */
    goBack() {
      window.history.back();
    },

    /* Upload de fichiers */
    async onFilesAdded(e) {
      /* Récupérer les fichiers de l'input */
      if (!e.target.files.length) {
        return;
      }

      /* Pour tous les fichiers ajoutés */
      await Promise.all(
        Array.from(e.target.files).map(async (fileToUpload) => {
          /* Ajouter le nom du fichier à la queue */
          const fileInQueue = {
            file_name: fileToUpload.name,
            is_directory: false,
            isFinished: false,
            isFailed: false,
            id: null,
          };

          /* Lancer le spinner */
          this.uploadQueue.push(fileInQueue);

          try {
            const uploadedFile = await filesModel.upload(
              this.currentFile.id,
              fileToUpload
            );

            /* Arreter le spinner */
            fileInQueue.isFinished = true;
            fileInQueue.id = uploadedFile.id;

            /* Verifier qu'on se trouve toujours dans le bon dossier */
            if (this.currentFile.id === uploadedFile.parent_directory) {
              /* Ajouter le fichier à la liste */
              this.files.push(uploadedFile);
            }

            /* Informer l'utilisateur du succès */
            this.$toasted.global.AppSucces({
              message:
                "Le fichier " + fileInQueue.file_name + " a bien été chargé",
            });
          } catch (err) {
            /* Informer l'utilisateur de l'échec */
            fileInQueue.isFailed = true;
            console.log(err);

            this.$toasted.global.AppError({
              message: err.msg,
            });
          }
        })
      );
    },

    /* Sauvegarder le fichier courant */
    async onSaveFile() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;

      try {
        if (this.selectedFiles.length > 0) {
          await Promise.all(
            this.selectedFiles.map(
              async (file) => await this.downloadFile(file, true)
            )
          );
        } else {
          await this.downloadFile(this.currentFile, true);
        }
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }

      this.isLoading = false;
    },

    /* Création de dossier */
    onCreateDirectory(name) {
      filesModel
        .createFolder(this.currentFile.id, name)
        .then((folder) => {
          this.$toasted.global.AppSucces({
            message: "Le dossier " + name + " a bien été créé",
          });

          if (this.currentFile.id === folder.parent_directory) {
            this.files.push(folder);
          }
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    /* Création de dossier */
    onCreateYearDirectory(year) {
      if (!year) return;
      filesModel
        .createYearFolder(this.currentFile.id, year)
        .then((folder) => {
          this.$toasted.global.AppSucces({
            message: "Le dossier " + name + " a bien été créé",
          });

          if (this.currentFile.id === folder.parent_directory) {
            this.files.push(folder);
          }
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    /* Mettre les fichiers à déplacer dans le presse papier */
    onCut() {
      this.cutFiles = this.selectedFiles.slice();
    },

    /* Déplacer les fichiers qui se trouvent dans le presse papier */
    onPaste() {
      this.cutFiles.forEach((file) => {
        filesModel
          .move(file.id, this.currentFile.id)
          .then(() => {
            file.parent_directory = this.currentFile.id;

            this.cutFiles = this.cutFiles.filter((f) => {
              return f.id !== file.id;
            });

            this.files.push(file);
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },

    /* Sélection de dossier */
    onSelect(files) {
      this.selectedFiles = files;
    },

    /* Limiter l'appel à la fonction de
     ** recherche pour éviter de surcharger le serveur */
    onSearch: throttle(function () {
      if (this.query === "") {
        return;
      }
      if (this.currentFile.id) {
        filesModel.search(this.currentFile.id, this.query).then((files) => {
          this.searchResults = files;
        });
      } else {
        const fuse = new Fuse(this.files, {
          shouldSort: true,
          threshold: 0.4,
          distance: 100,
          keys: ["file_name"],
        });
        this.searchResults = fuse.search(this.query).map((item) => item.item);
      }
    }, 100),

    /* Mettre des fichiers dans la corbeille */
    async onDelete() {
      const deleted = [];

      await Promise.all(
        this.selectedFiles.map(async (file) => {
          await filesModel.putInTrash(file.id);
          if (this.currentTab === "tab-trashed") {
            this.$toasted.global.AppSucces({
              message: `${file.file_name} a été définitivement supprimé.`,
            });
          } else {
            this.$toasted.global.AppSucces({
              message: `Le fichier ${file.file_name} a bien été supprimé.`,
            });
          }
          deleted.push(file);
        })
      ).catch((err) => {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      });

      this.files = this.files.filter((file) => !deleted.includes(file));
    },

    async onManage() {
      await Promise.all(
        this.selectedFiles.map(async (file) => {
          try {
            await filesModel.changeStatus(file.id);
            file.is_managed = !file.is_managed;
            this.$toasted.global.AppSucces({
              message: `Le fichier ${file.file_name} est ${
                file.is_managed ? "traité" : "non traité"
              }`,
            });
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          }
        })
      );
    },

    async onPermanentDelete() {
      await Promise.all(
        this.selectedFiles.map(async (item) => {
          try {
            await filesModel.remove(item.id);
            this.trashFiles = this.trashFiles.filter((i) => {
              return i.id !== item.id;
            });
            this.$toasted.global.AppSucces({
              message: item.file_name + " a été définitivement supprimé.",
            });
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          }
        })
      );
    },

    async onRestore() {
      await Promise.all(
        this.selectedFiles.map(async (item) => {
          try {
            await filesModel.restore(item.id);

            this.trashFiles = this.trashFiles.filter((i) => {
              return i.id !== item.id;
            });

            this.$toasted.global.AppSucces({
              message: "L'élément " + item.file_name + " a bien été restauré",
            });
          } catch (err) {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          }
        })
      );
    },

    /* Renommer un fichier */
    async onRenameFile(name) {
      try {
        const file = this.selectedFiles[0];

        await filesModel.rename(file.id, name);

        this.$toasted.global.AppSucces({
          message: `Le fichier ${file.file_name} a bien été renommé en ${name}`,
        });

        file.file_name = name;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async onLockFolder() {
      try {
        const folders = this.selectedFiles;
        await Promise.all(
          folders.map(
            async (folder) => await filesModel.lockFolder(folder.id, true)
          )
        );

        this.$refs.fullList.unCheckAll();

        this.getFile(this.$route.params.id);

        this.$toasted.global.AppSucces({
          message: this.$t("cloud.lock-folder-successful"),
        });
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async onUnLockFolder() {
      try {
        const folders = this.selectedFiles;
        await Promise.all(
          folders.map(
            async (folder) => await filesModel.lockFolder(folder.id, false)
          )
        );

        this.$toasted.global.AppSucces({
          message: this.$t("cloud.unlock-folder-successful"),
        });

        this.getFile(this.$route.params.id);

        this.$refs.fullList.unCheckAll();
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },
    async onUnlockSingleFolder({ item }) {
      try {
        await filesModel.lockFolder(item.id, false);

        this.$toasted.global.AppSucces({
          message: this.$t("cloud.unlock-folder-successful"),
        });

        this.getFile(this.$route.params.id);
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    getFolderName(name) {
      if (name === "Dépôt interne") {
        return "Cabinet";
      }
      if (name === "Dépôt externe") {
        return "Mes documents";
      }

      return name;
    },

    smallButtonClick(actionName) {
      switch (actionName) {
        case ACTIONS.PASTE:
          this.onPaste();
          break;

        case ACTIONS.RENAME:
          this.isTextModalOpen = true;
          this.textModalContext = ACTIONS.RENAME;
          break;

        case ACTIONS.DELETE:
          this.confirmModalContext = ACTIONS.DELETE;
          this.isConfirmModalOpen = true;
          break;

        case ACTIONS.MANAGE:
          this.confirmModalContext = ACTIONS.MANAGE;
          this.isConfirmModalOpen = true;
          break;

        case ACTIONS.CUT:
          this.onCut();
          break;

        case ACTIONS.UPLOAD:
          if (this.isLockedFolder) {
            //? Prevent upload new file in locked folder or we can find another way to prevent this
            this.$toasted.global.AppError({
              message: this.$t("cloud.lock-folder-prevented"),
            });
            break;
          }
          this.$refs.fileUpload.click();
          break;

        case ACTIONS.NEW_FOLDER:
          this.isTextModalOpen = true;
          this.textModalContext = ACTIONS.NEW_FOLDER;
          break;

        case ACTIONS.NEW_YEAR_FOLDER:
          this.isTextModalOpen = true;
          this.textModalContext = ACTIONS.NEW_YEAR_FOLDER;
          break;

        case ACTIONS.DELETE_PERMANENT:
          this.confirmModalContext = ACTIONS.DELETE_PERMANENT;
          this.isConfirmModalOpen = true;
          break;

        case ACTIONS.RESTORE:
          this.onRestore();
          break;

        case ACTIONS.DOWNLOAD:
          this.onSaveFile();
          break;

        case ACTIONS.LOCK:
          this.confirmModalContext = ACTIONS.LOCK;
          this.isConfirmModalOpen = true;
          break;

        case ACTIONS.UNLOCK:
          this.confirmModalContext = ACTIONS.UNLOCK;
          this.isConfirmModalOpen = true;
          break;
      }
    },

    async confirmAction() {
      switch (this.confirmModalContext) {
        case ACTIONS.DELETE:
          await this.onDelete();
          break;
        case ACTIONS.DELETE_PERMANENT:
          await this.onPermanentDelete();
          break;
        case ACTIONS.MANAGE:
          await this.onManage();
          break;
        case ACTIONS.LOCK:
          this.onLockFolder();
          break;
        case ACTIONS.UNLOCK:
          this.onUnLockFolder();
          break;
      }

      // Reset select
      this.selectedFiles = [];
    },

    textPromptAction(name) {
      switch (this.textModalContext) {
        case ACTIONS.NEW_FOLDER:
          this.onCreateDirectory(name);
          break;
        case ACTIONS.NEW_YEAR_FOLDER:
          this.onCreateYearDirectory(name);
          break;
        case ACTIONS.RENAME:
          this.onRenameFile(name);
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .md-theme-default {
    .cloud-browse {
      display: flex;
      flex-direction: column;
      height: 100%;

      .page-header {
        flex: 0 0 auto;

        .page-header__tabs-search {
          margin: 0;
        }
      }

      &__action-icon {
        fill: var(--bg-primary);
        color: var(--bg-primary);
      }

      &__spinner {
        margin: 40px auto;
      }

      &__file-name {
        font-weight: bold;
        color: var(--secondary, #000);

        &:hover {
          color: var(--text-primary);
        }
      }

      &__icon {
        .md-table-cell-container {
          padding: 0;
          display: flex;
          justify-content: center;
        }
      }

      &__lock-icon {
        .app-icon-big {
          width: 26px;
        }
      }

      &__buttons {
        margin-left: auto;
        display: flex;
        gap: 8px;
        .md-button {
          min-width: unset;
          width: var(--header-icon-size-lg);
          height: var(--header-icon-size-lg);
          min-height: unset;
          margin: 0;
          @include for-lg {
            width: var(--header-icon-size-xl);
            height: var(--header-icon-size-xl);
          }

          .app-icon,
          .app-icon-stroke-inverted {
            width: 100%;
            height: 100%;
          }
        }
      }

      #file-upload {
        display: block;
        visibility: hidden;
        width: 0;
        height: 0;
      }

      .cloud-path {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .md-primary--inverted {
          min-width: 50px;
          width: auto;
        }

        .md-button {
          margin: 0;
          background: transparent !important;
          text-transform: none;
          font-size: toRem(14);
          font-family: var(--font-bold);
          @include for-lg {
            font-size: toRem(20);
          }

          &:hover {
            color: #000 !important;
          }

          &.cloud-blue {
            color: var(--text-secondary, #000) !important;
          }
        }

        &__item {
          display: flex;
          align-items: center;
          justify-content: center;

          .md-icon {
            color: var(--text-inverted, #000);
          }

          .md-button {
            margin: 0;

            &.md-active {
              border-radius: 11px;
              color: var(--text-secondary, #000) !important;

              .app-icon {
                g,
                path {
                  fill: var(--text-secondary, #000);
                }
              }
            }
          }

          .app-icon {
            width: 13px;
            height: 13px;
            @include for-lg {
              height: 16px;
            }

            g,
            path {
              transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
            }
          }
        }
      }

      .cloud-list {
        padding: 0;
        border-radius: 4px;

        .no-item {
          margin-top: 0;
        }
      }

      .cloud-dock {
        position: fixed;
        z-index: 9999;
        bottom: 0;
        right: 16px;
        width: 360px;
        max-height: 40%;
        overflow: auto;
        border-radius: 15px;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
        border: 1px solid rgba(#000, 0.12);

        .failure {
          color: red !important;
        }

        .md-list-item-expand.md-active {
          border-color: #0000;
        }

        .md-list-item-content {
          padding: 0 30px 0 20px;

          &:hover {
            background-color: transparent !important;
          }

          .md-ripple-wave {
            display: none !important;
          }
        }

        .upload-status {
          .md-list-item-content {
            padding: 0 22px 0 20px;
          }

          .upload-title {
            font-size: 14px;
            font-family: var(--font-extrabold);
          }

          .md-button {
            margin: 0;
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }

        .md-list-action {
          .md-button-content {
            display: flex;
            justify-content: center;
          }
        }

        .icon-circle {
          margin-right: 10px;

          .toolbar-icon {
            margin-left: 5px;
          }
        }

        .md-progress-spinner {
          .md-progress-spinner-circle {
            stroke: var(--bg-primary);
          }
        }
      }

      .cloud-utils {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 toRem(29);
        flex: 0 0 auto;
        margin: 0 0 toRem(20) 0;
        @include for-lg {
          padding: 0 54px;
          margin: 0 0 toRem(37) 0;
        }

        .page-search {
          flex-shrink: 1;
          flex-grow: 0;
          height: 26px;
          position: relative;
          z-index: 2;
          @include for-lg {
            height: 40px;
          }

          &__input {
            width: 455px;
            height: 100%;
            font-size: 15px;
            color: var(--text-inverted, #000);
            padding: 7px 25px 7px 14px;
            border-radius: 5px;
            background-color: white;
            border: none;
            box-shadow: none;
          }

          &__icon {
            width: 12px;
            height: 12px;
            position: absolute;
            right: 10px;
            top: 7px;
            color: var(--bg-primary);

            @include for-lg {
              top: 10px;
              width: 20px;
              height: 20px;
            }

            g {
              stroke: var(--bg-primary) !important;
            }
          }
        }
      }

      .xlsx-table {
        margin-top: 1rem;
        height: calc(100vh - 350px);
      }

      #pdf-container {
        margin-top: 1rem;
        height: calc(100vh - 350px);
      }

      .image-container {
        width: 100%;
        height: calc(100vh - 350px);
        margin: 1rem auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .image-preview {
          max-height: 100%;
          height: auto;
          width: auto;
        }
      }

      &.cloud-page--jepa {
        .page-header {
          .page-header__item {
            .md-ripple {
              border-radius: 0;

              .md-button-jepa {
                color: var(--bg-primary);
                background: var(--text-primary);
              }

              &:hover {
                background: var(--bg-primary);
                color: var(--text-primary);

                .md-button-jepa {
                  color: var(--text-primary);
                  background: var(--bg-primary);
                }
              }
            }
          }
        }

        .sortable-list {
          .sortable-list__table {
            .md-primary--jepa--inverted {
              color: #003382;
            }
          }
        }
      }

      &.cloud-page--lcm {
        .cloud-path {
          .md-button {
            font-size: 20px !important;
            font-family: var(--font-bold) !important;

            &:hover {
              color: #000 !important;
            }

            &.cloud-blue {
              color: var(--primary, #000) !important;
            }
          }

          &__item {
            .md-icon {
              color: var(--text-inverted, #000);
            }

            .md-button {
              margin: 0;

              &.md-active {
                border-radius: 11px;
                color: var(--primary, #000) !important;
              }
            }
          }
        }
      }

      &.cloud-page--gc {
        .cloud-path {
          .md-button {
            font-size: 20px !important;
            font-family: var(--font-bold) !important;

            &:hover {
              color: #000 !important;
            }

            &.cloud-blue {
              color: var(--primary, #000) !important;
            }
          }

          &__item {
            .md-icon {
              color: var(--text-inverted, #000);
            }

            .md-button {
              margin: 0;

              &.md-active {
                border-radius: 11px;
                color: var(--primary, #000) !important;
              }
            }
          }
        }
      }

      &-company-folders {
        display: flex;
        flex-direction: row;
        gap: 36px;
      }

      &-content {
        .md-empty-state {
          margin-top: 32px;
        }

        &.page-layout {
          flex: 1 1 auto;
          height: auto;
          padding: 0 toRem(19) toRem(36) toRem(30);
          @include for-lg {
            padding: 0 toRem(44) toRem(34) toRem(54);
          }
        }

        .setting-card-medium {
          height: 254px;

          .md-ripple {
            font-size: toRem(12);
          }

          @include for-lg {
            height: 377px;
            .md-ripple {
              font-size: toRem(14);
            }
          }
        }

        .modal-body-content {
          .md-field.md-theme-default {
            &:before {
              background-color: var(--bg-primary);
            }
          }
        }
      }
    }
  }

  .sortable-list__table.md-theme-default.md-table {
    .md-table-row .md-table-cell.cloud-browse__lock-icon {
      .app-icon-big {
        width: 21px;
        height: 26px;
      }
    }
  }
}
</style>
